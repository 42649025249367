const { PROJECT_ID } = require("../datiPortale");

let codiciCentri;

if (PROJECT_ID == "bipcom") {
  codiciCentri = require("./CODICI_CENTRO_bipcom").codiciCentri;
} else if (PROJECT_ID == "bipcom-mrat") {
  codiciCentri = require("./CODICI_CENTRO_bipcom-mrat").codiciCentri;
} else if (PROJECT_ID == "riskmet") {
  codiciCentri = require("./CODICI_CENTRO_riskmet").codiciCentri;
} else {
  console.log("NOT DONE YET - codiciCentri ??? ");
  process.exit(1);
}

codiciCentri.forEach(obj => {
  obj.label = obj.codiceCentro + " - " + obj.nomeCentro;
});

export const codiceCentroMap = {};
codiciCentri.forEach(obj => {
  codiceCentroMap[obj.codiceCentro] = obj;
});

export function getCodiciCentroPossibilePerUser(user) {
  if (user.userType === "Admin") {
    return codiciCentri;
  }
  return [codiceCentroMap[user.codiceCentro]];
}

export function centroHaControlli(codiceCentro) {
  return (
    codiceCentroMap[codiceCentro] && codiceCentroMap[codiceCentro].haControlli
  );
}

export function centroHaEsmActigrafo(codiceCentro) {
  return (
    (codiceCentroMap[codiceCentro] &&
      codiceCentroMap[codiceCentro].haEsmActigrafo) ||
    false
  );
}
