import React from "react";
import { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert
} from "reactstrap";
import { get, map } from "lodash";
import fpSet from "lodash/fp/set";
import {
  getCodiciCentroPossibilePerUser,
  centroHaEsmActigrafo
} from "common/utils/codiceCentro.js";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import { getOperatori, updatePasswordOperatore } from "app/actions";
import DatetimePicker from "app/elements/DatetimePicker";
import NumberInput from "app/elements/NumberInput";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  tipiPazienti,
  tipiPazientiInfo,
  tipiControlli,
  tipiControlliInfo,
  checkTipoUtenteHasOperatoreDiRiferimento,
  checkTipoUtenteHasPazienteDiRiferimento
} from "common/tipiPazienti";
import { ASK_INFORMAZIONI_PERSONALI_PAZIENTE_E_ALTRE_COSE } from "common/datiPortale";
import {
  NUMERO_DI_VOLTE_ESM,
  CODICE_PAZIENTE_AUTOMATICO
} from "common/datiPortale";

class AbstractPartecipanteForm extends Component {
  static propTypes = {
    partecipante: PropTypes.object.isRequired,
    updatePartecipante: PropTypes.func,
    partecipanteErrors: PropTypes.object,
    isCreate: PropTypes.bool,
    isEditable: PropTypes.bool,

    showTipoPaziente: PropTypes.bool,
    showTipoControllo: PropTypes.bool,
    showOperatoreDiRiferimento: PropTypes.bool,
    showPazienteDiRiferimento: PropTypes.bool,
    showPassword: PropTypes.bool,
    isOperatore: PropTypes.bool,
    showScolarita: PropTypes.bool,
    showRitirato: PropTypes.bool,
    showEsmActigrafoApp: PropTypes.bool
  };

  static defaultProps = {
    isCreate: false,
    isEditable: true
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (
      this.props.isCreate &&
      this.props.showTipoPaziente &&
      tipiPazienti.length === 1
    ) {
      this.props.updatePartecipante(
        fpSet("userType", tipiPazienti[0], this.props.partecipante)
      );
    }
    if (
      this.props.isCreate &&
      this.props.showTipoControllo &&
      tipiControlli.length === 1
    ) {
      this.props.updatePartecipante(
        fpSet("userType", tipiControlli[0], this.props.partecipante)
      );
    }
  }

  creaNuovaPassword = () => {
    this.props
      .updatePasswordOperatore(get(this.props, "operatore.id"))
      .then(action => {
        if (action.callApiSuccess) {
          this.setState(
            fpSet(
              "operatorePasswordModal.result",
              get(action, "response"),
              this.state
            )
          );
        } else {
          this.setState(
            fpSet(
              "operatorePasswordModal.error",
              get(action, "response"),
              this.state
            )
          );
        }
      });
  };

  render() {
    const { t, isCreate } = this.props;
    return (
      <Row>
        <Col sm="12">
          <Form>
            {(!isCreate || CODICE_PAZIENTE_AUTOMATICO) && (
              <FormGroup>
                <Label>
                  {this.props.isOperatore
                    ? t("codice_operatore_label")
                    : t("codice_partecipante_label")}
                </Label>

                <Input
                  type="text"
                  placeholder={t("autogenerato_label")}
                  disabled={true}
                  value={get(this.props, "partecipante.codiceUtente") || ""}
                  onChange={event => {
                    this.props.updatePartecipante(
                      fpSet(
                        "codiceUtente",
                        event.target.value,
                        this.props.partecipante
                      )
                    );
                  }}
                  invalid={
                    !!get(this.props, "partecipanteErrors.errors.codiceUtente")
                  }
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label>{t("centro_label")} </Label>

              <Input
                type="select"
                disabled={CODICE_PAZIENTE_AUTOMATICO ? false : !isCreate}
                value={get(this.props, "partecipante.codiceCentro") || ""}
                onChange={event => {
                  this.props.updatePartecipante(
                    fpSet(
                      "codiceCentro",
                      event.target.value,
                      this.props.partecipante
                    )
                  );
                }}
                invalid={
                  !!get(this.props, "partecipanteErrors.errors.codiceCentro")
                }
              >
                <option></option>
                {map(
                  getCodiciCentroPossibilePerUser(get(this.props, "user")),
                  (option, index) => {
                    const value = get(option, "codiceCentro");
                    const label = option.label;
                    return (
                      <option key={index} value={value}>
                        {label}
                      </option>
                    );
                  }
                )}
              </Input>

              <FormFeedback>
                {get(this.props, "partecipanteErrors.errors.codiceCentro")}
              </FormFeedback>
            </FormGroup>

            {isCreate && !CODICE_PAZIENTE_AUTOMATICO && (
              <FormGroup>
                <Label>
                  {this.props.isOperatore
                    ? t("codice_operatore_label")
                    : t("codice_partecipante_label")}
                </Label>

                <Input
                  type="text"
                  value={get(this.props, "partecipante.codiceUtente") || ""}
                  onChange={event => {
                    this.props.updatePartecipante(
                      fpSet(
                        "codiceUtente",
                        event.target.value,
                        this.props.partecipante
                      )
                    );
                  }}
                  invalid={
                    !!get(this.props, "partecipanteErrors.errors.codiceUtente")
                  }
                />

                <div style={{ marginTop: 10 }}>
                  <code style={{ color: "black" }}>
                    {(get(this.props, "partecipante.codiceCentro") || "...") +
                      (get(this.props, "partecipante.codiceUtente") || "...")}
                  </code>
                </div>

                <FormFeedback>
                  {get(this.props, "partecipanteErrors.errors.codiceUtente")}
                </FormFeedback>
              </FormGroup>
            )}

            {this.props.showTipoPaziente && tipiPazienti.length > 1 && (
              <FormGroup>
                <Label>{t("partecipante_tipo_label")} </Label>

                <Input
                  type="select"
                  value={get(this.props, "partecipante.userType") || ""}
                  onChange={event => {
                    this.props.updatePartecipante(
                      fpSet(
                        "userType",
                        event.target.value,
                        this.props.partecipante
                      )
                    );
                  }}
                  invalid={
                    !!get(this.props, "partecipanteErrors.errors.userType")
                  }
                >
                  <option value={""}></option>
                  {tipiPazienti.map(tipo => {
                    return (
                      <option key={tipo} value={tipo}>
                        {t(tipiPazientiInfo[tipo].i18nKey)}
                      </option>
                    );
                  })}
                </Input>

                <FormFeedback>
                  {get(this.props, "partecipanteErrors.errors.userType")}
                </FormFeedback>
              </FormGroup>
            )}

            {this.props.showTipoControllo && tipiControlli.length > 1 && (
              <FormGroup>
                <Label>{t("partecipante_tipo_label")} </Label>

                <Input
                  type="select"
                  value={get(this.props, "partecipante.userType") || ""}
                  onChange={event => {
                    this.props.updatePartecipante(
                      fpSet(
                        "userType",
                        event.target.value,
                        this.props.partecipante
                      )
                    );
                  }}
                  invalid={
                    !!get(this.props, "partecipanteErrors.errors.userType")
                  }
                >
                  <option value={""}></option>
                  {tipiControlli.map(tipo => {
                    return (
                      <option key={tipo} value={tipo}>
                        {t(tipiControlliInfo[tipo].i18nKey)}
                      </option>
                    );
                  })}
                </Input>

                <FormFeedback>
                  {get(this.props, "partecipanteErrors.errors.userType")}
                </FormFeedback>
              </FormGroup>
            )}

            {this.props.showOperatoreDiRiferimento &&
            checkTipoUtenteHasOperatoreDiRiferimento(
              get(this.props, "partecipante.userType")
            ) ? (
              <FormGroup>
                <Label>{t("operatore_di_riferimento_label")} </Label>

                <AsyncData loaderAction={this.props.getOperatori}>
                  {({ loading, loadingError, data }) => {
                    console.log({ loading, loadingError, data });
                    if (loading) {
                      return <Loading />;
                    }
                    if (loadingError) {
                      return (
                        <Alert color="danger">{loadingError.message}</Alert>
                      );
                    }
                    return (
                      <Input
                        type="select"
                        value={
                          get(
                            this.props,
                            "partecipante.operatoreAssociatoId"
                          ) || ""
                        }
                        onChange={event => {
                          this.props.updatePartecipante(
                            fpSet(
                              "operatoreAssociatoId",
                              event.target.value,
                              this.props.partecipante
                            )
                          );
                        }}
                        invalid={
                          !!get(
                            this.props,
                            "partecipanteErrors.errors.operatoreAssociatoId"
                          )
                        }
                      >
                        <option></option>
                        {map(data, (option, index) => {
                          const value = get(option, "id");
                          const label = get(option, "codiceUtente");
                          return (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          );
                        })}
                      </Input>
                    );
                  }}
                </AsyncData>
              </FormGroup>
            ) : null}

            {this.props.showPazienteDiRiferimento &&
            checkTipoUtenteHasPazienteDiRiferimento(
              get(this.props, "partecipante.userType")
            ) ? (
              <FormGroup>
                <Label>{t("paziente_di_riferimento_label")} </Label>

                <Input
                  type="text"
                  value={
                    get(this.props, "partecipante.pazientiAssociatoText") || ""
                  }
                  onChange={event => {
                    this.props.updatePartecipante(
                      fpSet(
                        "pazientiAssociatoText",
                        event.target.value,
                        this.props.partecipante
                      )
                    );
                  }}
                  invalid={
                    !!get(
                      this.props,
                      "partecipanteErrors.errors.pazientiAssociatoText"
                    )
                  }
                />
              </FormGroup>
            ) : null}

            {this.props.isOperatore &&
            get(this.props, "operatore.id") &&
            get(this.props, "operatore.adminCentro") ? (
              <>
                <hr />

                <h5>Password operatore </h5>

                <FormGroup>
                  <Button
                    color="warning"
                    onClick={() => {
                      this.setState(
                        fpSet("operatorePasswordModal", {}, this.state)
                      );
                    }}
                  >
                    Modifica password{" "}
                  </Button>

                  <FormText>
                    Una nuova password verrà generata e la vecchia password
                    smetterà di funzionare. La nuova password verrà mostrata
                    solo una volta.{" "}
                  </FormText>
                </FormGroup>

                <Modal
                  size="md"
                  isOpen={!!get(this.state, "operatorePasswordModal")}
                >
                  <ModalHeader
                    toggle={() => {
                      this.setState(
                        fpSet("operatorePasswordModal", false, this.state)
                      );
                    }}
                  >
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>Nuova password operatore </>
                    )}
                  </ModalHeader>

                  <ModalBody>
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>
                        {!get(this.state, "operatorePasswordModal.result") ? (
                          "Clicca il pulsante per generare una nuova password. La vecchia password non sarà più valida."
                        ) : (
                          <Row>
                            <Col sm="12">
                              <div style={{ marginBottom: 20 }}>
                                Le nuove credenziali sono:{" "}
                              </div>
                              <Alert color="success">
                                Nome utente:{" "}
                                {get(
                                  this.state,
                                  "operatorePasswordModal.result.data.user.email"
                                ) || ""}
                                <div />
                                Password:{" "}
                                {get(
                                  this.state,
                                  "operatorePasswordModal.result.data.password"
                                ) || ""}
                              </Alert>
                              Prendine nota. Non potrà più essere visualizzata.{" "}
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    {!!get(this.state, "operatorePasswordModal") && (
                      <>
                        {!get(this.state, "operatorePasswordModal.result") ? (
                          <>
                            {get(this.state, "operatorePasswordModal.error") &&
                            get(this.state, "operatorePasswordModal.error")
                              .message ? (
                              <div className="text-danger">
                                {
                                  get(
                                    this.state,
                                    "operatorePasswordModal.error"
                                  ).message
                                }
                              </div>
                            ) : null}

                            <Button
                              color="secondary"
                              onClick={() => {
                                this.setState(
                                  fpSet(
                                    "operatorePasswordModal",
                                    false,
                                    this.state
                                  )
                                );
                              }}
                            >
                              Annulla{" "}
                            </Button>

                            <Button
                              color="primary"
                              onClick={() => {
                                this.creaNuovaPassword();
                              }}
                            >
                              Crea nuova password{" "}
                            </Button>
                          </>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={() => {
                              this.setState(
                                fpSet(
                                  "operatorePasswordModal",
                                  false,
                                  this.state
                                )
                              );
                            }}
                          >
                            Ok{" "}
                          </Button>
                        )}
                      </>
                    )}
                  </ModalFooter>
                </Modal>
              </>
            ) : null}

            {ASK_INFORMAZIONI_PERSONALI_PAZIENTE_E_ALTRE_COSE && (
              <>
                <hr />

                <h5>{t("personal_info_title_label")}</h5>

                <FormGroup>
                  <Label>{t("data_di_nascita_label")} </Label>

                  <DatetimePicker
                    date={true}
                    value={
                      get(this.props, "partecipante.dataDiNascita") || null
                    }
                    onChange={(event, value) => {
                      this.props.updatePartecipante(
                        fpSet("dataDiNascita", value, this.props.partecipante)
                      );
                    }}
                    invalid={
                      !!get(
                        this.props,
                        "partecipanteErrors.errors.dataDiNascita"
                      )
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label>{t("eta_label")} </Label>

                  <NumberInput
                    value={get(this.props, "partecipante.etaAllInizio") || null}
                    onChange={(event, value) => {
                      this.props.updatePartecipante(
                        fpSet("etaAllInizio", value, this.props.partecipante)
                      );
                    }}
                    invalid={
                      !!get(
                        this.props,
                        "partecipanteErrors.errors.etaAllInizio"
                      )
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label>{t("genere_label")} </Label>

                  <Input
                    type="select"
                    value={get(this.props, "partecipante.genere") || ""}
                    onChange={event => {
                      this.props.updatePartecipante(
                        fpSet(
                          "genere",
                          event.target.value,
                          this.props.partecipante
                        )
                      );
                    }}
                    invalid={
                      !!get(this.props, "partecipanteErrors.errors.genere")
                    }
                  >
                    <option value={""}></option>
                    <option value={"Maschio"}>{t("genere_maschio")}</option>
                    <option value={"Femmina"}>{t("genere_femmina")}</option>
                  </Input>
                </FormGroup>

                {this.props.showScolarita && (
                  <FormGroup>
                    <Label>{t("scolarita_label")} </Label>

                    <NumberInput
                      value={
                        get(this.props, "partecipante.scolaritaAnni") || null
                      }
                      onChange={(event, value) => {
                        this.props.updatePartecipante(
                          fpSet("scolaritaAnni", value, this.props.partecipante)
                        );
                      }}
                      invalid={
                        !!get(
                          this.props,
                          "partecipanteErrors.errors.scolaritaAnni"
                        )
                      }
                    />
                  </FormGroup>
                )}

                <hr />

                <h5>{t("dati_sperimentazione_title_label")} </h5>

                <FormGroup style={{ marginTop: 20 }} check={true}>
                  <Input
                    type="checkbox"
                    checked={!!get(this.props, "partecipante.completato")}
                    onChange={event => {
                      this.props.updatePartecipante(
                        fpSet(
                          "completato",
                          event.target.checked,
                          this.props.partecipante
                        )
                      );
                    }}
                    invalid={
                      !!get(this.props, "partecipanteErrors.errors.completato")
                    }
                  />

                  <Label check={true}>
                    {t("raccolta_dati_completata_label")}{" "}
                  </Label>
                </FormGroup>

                {this.props.showRitirato && (
                  <FormGroup style={{ marginTop: 20 }} check={true}>
                    <Input
                      type="checkbox"
                      checked={!!get(this.props, "partecipante.ritirato")}
                      onChange={event => {
                        this.props.updatePartecipante(
                          fpSet(
                            "ritirato",
                            event.target.checked,
                            this.props.partecipante
                          )
                        );
                      }}
                      invalid={
                        !!get(this.props, "partecipanteErrors.errors.ritirato")
                      }
                    />

                    <Label check={true}>{t("ritirato_label")} </Label>
                  </FormGroup>
                )}

                {this.props.showRitirato &&
                get(this.props, "partecipante.ritirato") ? (
                  <div style={{ marginTop: 20, marginLeft: 30 }}>
                    <FormGroup>
                      <Label>{t("motivazione_ritiro_label")} </Label>

                      <Input
                        type="select"
                        value={
                          get(this.props, "partecipante.ritiratoMotivazione") ||
                          ""
                        }
                        onChange={event => {
                          this.props.updatePartecipante(
                            fpSet(
                              "ritiratoMotivazione",
                              event.target.value,
                              this.props.partecipante
                            )
                          );
                        }}
                        invalid={
                          !!get(
                            this.props,
                            "partecipanteErrors.errors.ritiratoMotivazione"
                          )
                        }
                      >
                        <option value={""}></option>
                        <option value={0}>
                          {t("motivazione_ritiro__troppo_impegnato")}
                        </option>
                        <option value={1}>
                          {t(
                            "motivazione_ritiro__rifiuta_valutazione_iniziale"
                          )}
                        </option>
                        <option value={3}>
                          {t("motivazione_ritiro__nessuna_specifica")}
                        </option>
                        <option value={4}>
                          {t("motivazione_ritiro__spostamento_e_altro")}
                        </option>
                        <option value={5}>
                          {t("motivazione_ritiro__non_vuole_spiegare")}
                        </option>
                      </Input>

                      <FormFeedback>
                        {get(this.props, "partecipanteErrors.errors.userType")}
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup style={{ marginTop: 20 }} check={true}>
                      <Input
                        type="checkbox"
                        checked={
                          !!get(this.props, "partecipante.ritiratoUsoDati")
                        }
                        onChange={event => {
                          this.props.updatePartecipante(
                            fpSet(
                              "ritiratoUsoDati",
                              event.target.checked,
                              this.props.partecipante
                            )
                          );
                        }}
                        invalid={
                          !!get(
                            this.props,
                            "partecipanteErrors.errors.ritiratoUsoDati"
                          )
                        }
                      />

                      <Label check={true}>
                        {t("soggetto_accetta_utilizzo_dati")}{" "}
                      </Label>
                    </FormGroup>
                  </div>
                ) : null}

                {this.props.showEsmActigrafoApp &&
                centroHaEsmActigrafo(
                  get(this.props, "partecipante.codiceCentro")
                ) ? (
                  <FormGroup style={{ marginTop: 20 }} check={true}>
                    <Input
                      type="checkbox"
                      checked={
                        !!get(this.props, "partecipante.partecipaObiettivo2")
                      }
                      onChange={event => {
                        this.props.updatePartecipante(
                          fpSet(
                            "partecipaObiettivo2",
                            event.target.checked,
                            this.props.partecipante
                          )
                        );
                      }}
                      invalid={
                        !!get(
                          this.props,
                          "partecipanteErrors.errors.partecipaObiettivo2"
                        )
                      }
                    />

                    <Label check={true}>
                      {t("partecipa_a_esm_actigrafo")}{" "}
                    </Label>
                  </FormGroup>
                ) : null}

                {this.props.showEsmActigrafoApp &&
                get(this.props, "partecipante.partecipaObiettivo2") &&
                get(this.props, "partecipante.id")
                  ? new Array(NUMERO_DI_VOLTE_ESM).fill(0).map((_, index) => {
                      const inizioObiettivo2Key =
                        "inizioObiettivo2" + (index ? "_" + (index + 1) : "");

                      const label = index ? "(" + (index + 1) + ")" : "";

                      return (
                        <Row style={{ marginTop: index === 0 ? 20 : 0 }}>
                          <Col md={6}>
                            <FormGroup>
                              <Label>
                                {t("data_inizio_label")} {label}
                              </Label>

                              <DatetimePicker
                                date={true}
                                value={
                                  get(
                                    this.props,
                                    "partecipante." + inizioObiettivo2Key
                                  ) || null
                                }
                                onChange={(event, value) => {
                                  this.props.updatePartecipante(
                                    fpSet(
                                      inizioObiettivo2Key,
                                      value,
                                      this.props.partecipante
                                    )
                                  );
                                }}
                                invalid={
                                  !!get(
                                    this.props,
                                    "partecipanteErrors.errors." +
                                      inizioObiettivo2Key
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      );
                    })
                  : null}
              </>
            )}
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {
  getOperatori: getOperatori,
  updatePasswordOperatore: updatePasswordOperatore
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AbstractPartecipanteForm)
);
