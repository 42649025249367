import React from "react";
import { Component } from "react";
import { get } from "lodash";
import Main from "app/components/mobile/Main.js";
import Login from "app/components/mobile/Login.js";
import { mobileUiGetPushNotificationToken } from "common/utils/mobileUiGetPushNotificationToken.js";
import { connect } from "react-redux";

class MobileUI extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    // Se `user` cambia, esegui un refresh della pagina
    // questo persiste i cookie per android
    if (prevProps.user !== this.props.user) {
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        {get(this.props, "user") ? (
          <Main />
        ) : (
          <Login notificationToken={mobileUiGetPushNotificationToken()} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: get(state, "auth.user")
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileUI);
