import { find } from "lodash";
import moment from "moment";

const MAX_NOTIFICA_DIFF_MS = 30 * 60 * 1000; // 30 min
const DIFF_0 = -5 * 60 * 1000; // fare che può essere 5 min nel futuro e può rispondere

const isProd = process.env.NODE_ENV === "production";
const TEST_NOTIFICHE = !isProd && false;

function isNotificaAttiva(notifica, diff0) {
  if (notifica.dataCompilazione) {
    return false;
  }

  const now = moment();
  const dataInvio = moment(notifica.dataInvio);

  const diff = now.diff(dataInvio);

  // dataInvio > now, è nel futuro!
  if (diff < diff0) {
    return false;
  }

  if (!TEST_NOTIFICHE && diff > MAX_NOTIFICA_DIFF_MS) {
    return false;
  }
  return true;
}

export default function getNotificaDovutaOra(user, diff0 = DIFF_0) {
  for (let index = 0; index < 5; index++) {
    const notifiche = user["notifiche" + (index ? index + 1 : "")] || [];
    const x =
      find(notifiche, notifica => isNotificaAttiva(notifica, diff0)) || null;
    if (x) {
      return x;
    }
  }
  return null;
}

export function getNotificaDovutaOra_WithIndex(index, user, diff0 = DIFF_0) {
  const notifiche = user["notifiche" + (index ? index + 1 : "")] || [];
  const x =
    find(notifiche, notifica => isNotificaAttiva(notifica, diff0)) || null;
  if (x) {
    return x;
  }
  return null;
}
