export default [
  {
    type: "Visit number 1",
    name: "Variables for MRAT (visit 1)",
    codiceDataCompletamento: "DATE_MRAT_T0",
    filtro: null,
    domande: [
      {
        key: "DATE_MRAT_T0",
        label: "Date",
        type: "date"
      },
      {
        key: "MRAT_T0_1",
        label: "Visit Number",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_1a",
        label: "Sex",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Male"
          },
          {
            value: 2,
            label: "Female"
          }
        ]
      },
      {
        key: "MRAT_T0_1b",
        label: "Age",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_1c",
        label: "Marital status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_1d",
        label: "Children",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_2",
        label: "Total Education Years",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_2a",
        label: "Highest Educational Qulification",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "No school graduation"
          },
          {
            value: 2,
            label: "Compulsory schooling"
          },
          {
            value: 3,
            label: "EQR Level 3\n(Secondary School Diploma)"
          },
          {
            value: 4,
            label:
              "EQR Level 4 \n(Equivalent for \nA-Levels/Abitur/Baccalauréat/Matura)"
          },
          {
            value: 5,
            label: "Bachelors Degree/\nGraduate Certificate"
          },
          {
            value: 6,
            label: "Masters Degree/\nPostgraduate Certificate"
          },
          {
            value: 7,
            label: "Doctorate / PhD"
          },
          {
            value: 8,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_2b",
        label: "Current occupational status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Unemployed"
          },
          {
            value: 2,
            label: "Early retirement"
          },
          {
            value: 3,
            label: "Retirement"
          },
          {
            value: 4,
            label: "Parttime"
          },
          {
            value: 5,
            label: "Fulltime"
          },
          {
            value: 6,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_2c",
        label: "Age of first contact with mental health services",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_2d",
        label: "Bipolar Disorder, according to ICD-10",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "F31"
          },
          {
            value: 2,
            label: "F31.0"
          },
          {
            value: 3,
            label: "F31.1"
          },
          {
            value: 4,
            label: "F31.2"
          },
          {
            value: 5,
            label: "F31.3"
          },
          {
            value: 6,
            label: "F31.4"
          },
          {
            value: 7,
            label: "F31.5"
          },
          {
            value: 8,
            label: "F31.6"
          },
          {
            value: 9,
            label: "F31.7"
          },
          {
            value: 10,
            label: "F31.8"
          },
          {
            value: 11,
            label: "F31.9"
          }
        ]
      },
      {
        key: "MRAT_T0_2f",
        label: "Bipolar Disorder I or II or NOS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Bipolar I"
          },
          {
            value: 2,
            label: "Bipolar II"
          },
          {
            value: 3,
            label: "Bipolar NOS"
          }
        ]
      },
      {
        key: "MRAT_T0_3",
        label: "Psychiatric Comorbidity ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_3a",
        label: "Psychiatric Comorbidity (please insert ICD-10 codes) ",
        if: [
          {
            key: "MRAT_T0_3",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "MRAT_T0_3b",
        label: "Number of lifetime psychiatric hospitalizations",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_3c",
        label: "Overall lenght of psychiatric hopsitalizations [months]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_3d",
        label: "Lifetime substance or alcohol abuse disorder",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_4",
        label: "Lifetime treatment with Lithium (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_4a",
        label: "Lifteime treatment with mood stabilizers (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_4b",
        label: "Lifetime treatment with antipsychotics (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_4c",
        label: "Elixhauser Comorbidity Index",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_4d",
        label:
          "Diagnosis of Medical Comorbidities (present or past, using ICD-10 codes)",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_5",
        label: "BMI [kg/m2]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_5a",
        label: "Smoking (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Quit"
          },
          {
            value: 4,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_5b",
        label: "Sleep Problems",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T0_5b1",
        label: "BIOMARKERS (enter 9999 if unknown)",
        if: null,
        type: "description"
      },
      {
        key: "MRAT_T0_5c",
        label: "CRP [mg/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_6",
        label: "Fasting glucose [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_6a",
        label: "HbA1c [%]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_6b",
        label: "HDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_6c",
        label: "LDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_6d",
        label: "TG [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_7",
        label: "Total cholesterol [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_7a",
        label: "TSH [mIU/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_9",
        label: "Haemoglobin [g/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_10",
        label: "White blood cell count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_11",
        label: " Platelet count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_7b",
        label: "ALT[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_7c",
        label: "AST[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T0_7d",
        label: "GGT [U/l]",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "Visit number 2",
    name: "Variables for MRAT (visit 2)",
    codiceDataCompletamento: "DATE_MRAT_T1",
    filtro: null,
    domande: [
      {
        key: "DATE_MRAT_T1",
        label: "Date",
        type: "date"
      },
      {
        key: "MRAT_T1_1",
        label: "Visit Number",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_1a",
        label: "Sex",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Male"
          },
          {
            value: 2,
            label: "Female"
          }
        ]
      },
      {
        key: "MRAT_T1_1b",
        label: "Age",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_1c",
        label: "Marital status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_1d",
        label: "Children",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_2",
        label: "Total Education Years",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_2a",
        label: "Highest Educational Qulification",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "No school graduation"
          },
          {
            value: 2,
            label: "Compulsory schooling"
          },
          {
            value: 3,
            label: "EQR Level 3\n(Secondary School Diploma)"
          },
          {
            value: 4,
            label:
              "EQR Level 4 \n(Equivalent for \nA-Levels/Abitur/Baccalauréat/Matura)"
          },
          {
            value: 5,
            label: "Bachelors Degree/\nGraduate Certificate"
          },
          {
            value: 6,
            label: "Masters Degree/\nPostgraduate Certificate"
          },
          {
            value: 7,
            label: "Doctorate / PhD"
          },
          {
            value: 8,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_2b",
        label: "Current occupational status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Unemployed"
          },
          {
            value: 2,
            label: "Early retirement"
          },
          {
            value: 3,
            label: "Retirement"
          },
          {
            value: 4,
            label: "Parttime"
          },
          {
            value: 5,
            label: "Fulltime"
          },
          {
            value: 6,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_2c",
        label: "Age of first contact with mental health services",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_2d",
        label: "Bipolar Disorder, according to ICD-10",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "F31"
          },
          {
            value: 2,
            label: "F31.0"
          },
          {
            value: 3,
            label: "F31.1"
          },
          {
            value: 4,
            label: "F31.2"
          },
          {
            value: 5,
            label: "F31.3"
          },
          {
            value: 6,
            label: "F31.4"
          },
          {
            value: 7,
            label: "F31.5"
          },
          {
            value: 8,
            label: "F31.6"
          },
          {
            value: 9,
            label: "F31.7"
          },
          {
            value: 10,
            label: "F31.8"
          },
          {
            value: 11,
            label: "F31.9"
          }
        ]
      },
      {
        key: "MRAT_T1_2f",
        label: "Bipolar Disorder I or II or NOS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Bipolar I"
          },
          {
            value: 2,
            label: "Bipolar II"
          },
          {
            value: 3,
            label: "Bipolar NOS"
          }
        ]
      },
      {
        key: "MRAT_T1_3",
        label: "Psychiatric Comorbidity ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_3a",
        label: "Psychiatric Comorbidity (please insert ICD-10 codes) ",
        if: [
          {
            key: "MRAT_T1_3",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "MRAT_T1_3b",
        label: "Number of lifetime psychiatric hospitalizations",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_3c",
        label: "Overall lenght of psychiatric hopsitalizations [months]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_3d",
        label: "Lifetime substance or alcohol abuse disorder",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_4",
        label: "Lifetime treatment with Lithium (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_4a",
        label: "Lifteime treatment with mood stabilizers (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_4b",
        label: "Lifetime treatment with antipsychotics (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_4c",
        label: "Elixhauser Comorbidity Index",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_4d",
        label:
          "Diagnosis of Medical Comorbidities (present or past, using ICD-10 codes)",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_5",
        label: "BMI [kg/m2]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_5a",
        label: "Smoking (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Quit"
          },
          {
            value: 4,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_5b",
        label: "Sleep Problems",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T1_5b1",
        label: "BIOMARKERS (enter 9999 if unknown)",
        if: null,
        type: "description"
      },
      {
        key: "MRAT_T1_5c",
        label: "CRP [mg/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_6",
        label: "Fasting glucose [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_6a",
        label: "HbA1c [%]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_6b",
        label: "HDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_6c",
        label: "LDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_6d",
        label: "TG [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_7",
        label: "Total cholesterol [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_7a",
        label: "TSH [mIU/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_9",
        label: "Haemoglobin [g/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_10",
        label: "White blood cell count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_11",
        label: " Platelet count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_7b",
        label: "ALT[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_7c",
        label: "AST[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T1_7d",
        label: "GGT [U/l]",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "Visit number 3",
    name: "Variables for MRAT (visit 3)",
    codiceDataCompletamento: "DATE_MRAT_T2",
    filtro: null,
    domande: [
      {
        key: "DATE_MRAT_T2",
        label: "Date",
        type: "date"
      },
      {
        key: "MRAT_T2_1",
        label: "Visit Number",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_1a",
        label: "Sex",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Male"
          },
          {
            value: 2,
            label: "Female"
          }
        ]
      },
      {
        key: "MRAT_T2_1b",
        label: "Age",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_1c",
        label: "Marital status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_1d",
        label: "Children",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_2",
        label: "Total Education Years",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_2a",
        label: "Highest Educational Qulification",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "No school graduation"
          },
          {
            value: 2,
            label: "Compulsory schooling"
          },
          {
            value: 3,
            label: "EQR Level 3\n(Secondary School Diploma)"
          },
          {
            value: 4,
            label:
              "EQR Level 4 \n(Equivalent for \nA-Levels/Abitur/Baccalauréat/Matura)"
          },
          {
            value: 5,
            label: "Bachelors Degree/\nGraduate Certificate"
          },
          {
            value: 6,
            label: "Masters Degree/\nPostgraduate Certificate"
          },
          {
            value: 7,
            label: "Doctorate / PhD"
          },
          {
            value: 8,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_2b",
        label: "Current occupational status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Unemployed"
          },
          {
            value: 2,
            label: "Early retirement"
          },
          {
            value: 3,
            label: "Retirement"
          },
          {
            value: 4,
            label: "Parttime"
          },
          {
            value: 5,
            label: "Fulltime"
          },
          {
            value: 6,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_2c",
        label: "Age of first contact with mental health services",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_2d",
        label: "Bipolar Disorder, according to ICD-10",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "F31"
          },
          {
            value: 2,
            label: "F31.0"
          },
          {
            value: 3,
            label: "F31.1"
          },
          {
            value: 4,
            label: "F31.2"
          },
          {
            value: 5,
            label: "F31.3"
          },
          {
            value: 6,
            label: "F31.4"
          },
          {
            value: 7,
            label: "F31.5"
          },
          {
            value: 8,
            label: "F31.6"
          },
          {
            value: 9,
            label: "F31.7"
          },
          {
            value: 10,
            label: "F31.8"
          },
          {
            value: 11,
            label: "F31.9"
          }
        ]
      },
      {
        key: "MRAT_T2_2f",
        label: "Bipolar Disorder I or II or NOS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Bipolar I"
          },
          {
            value: 2,
            label: "Bipolar II"
          },
          {
            value: 3,
            label: "Bipolar NOS"
          }
        ]
      },
      {
        key: "MRAT_T2_3",
        label: "Psychiatric Comorbidity ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_3a",
        label: "Psychiatric Comorbidity (please insert ICD-10 codes) ",
        if: [
          {
            key: "MRAT_T2_3",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "MRAT_T2_3b",
        label: "Number of lifetime psychiatric hospitalizations",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_3c",
        label: "Overall lenght of psychiatric hopsitalizations [months]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_3d",
        label: "Lifetime substance or alcohol abuse disorder",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_4",
        label: "Lifetime treatment with Lithium (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_4a",
        label: "Lifteime treatment with mood stabilizers (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_4b",
        label: "Lifetime treatment with antipsychotics (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_4c",
        label: "Elixhauser Comorbidity Index",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_4d",
        label:
          "Diagnosis of Medical Comorbidities (present or past, using ICD-10 codes)",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_5",
        label: "BMI [kg/m2]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_5a",
        label: "Smoking (present or past)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Quit"
          },
          {
            value: 4,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_5b",
        label: "Sleep Problems",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Unknown"
          }
        ]
      },
      {
        key: "MRAT_T2_5b1",
        label: "BIOMARKERS (enter 9999 if unknown)",
        if: null,
        type: "description"
      },
      {
        key: "MRAT_T2_5c",
        label: "CRP [mg/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_6",
        label: "Fasting glucose [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_6a",
        label: "HbA1c [%]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_6b",
        label: "HDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_6c",
        label: "LDL [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_6d",
        label: "TG [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_7",
        label: "Total cholesterol [mg/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_7a",
        label: "TSH [mIU/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_9",
        label: "Haemoglobin [g/dl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_10",
        label: "White blood cell count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_11",
        label: " Platelet count [/µl]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_7b",
        label: "ALT[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_7c",
        label: "AST[U/l]",
        if: null,
        type: "free-text"
      },
      {
        key: "MRAT_T2_7d",
        label: "GGT [U/l]",
        if: null,
        type: "free-text"
      }
    ]
  }
];
