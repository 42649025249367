import React from "react";
import { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

class LinkDownloadFileActigrafo extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user, asInput, fileActigrafoGrezziPathIndex } = this.props;

    let content = null;

    const fileActigrafoGrezziPath =
      user &&
      user[
        "fileActigrafoGrezziPath" +
          (fileActigrafoGrezziPathIndex ? fileActigrafoGrezziPathIndex + 1 : "")
      ];
    if (fileActigrafoGrezziPath) {
      let lastSlashIndex = fileActigrafoGrezziPath.lastIndexOf("/") + 1;
      const fileName = fileActigrafoGrezziPath.substring(lastSlashIndex);
      content = (
        <a href={fileActigrafoGrezziPath} target="_blank">
          {fileName}
        </a>
      );
    }

    return content;
  }
}

export default LinkDownloadFileActigrafo;
