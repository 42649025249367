export const codiciCentri = [
  {
    codiceCentro: "bre",
    nomeCentro: "Brescia",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "fra",
    nomeCentro: "Frankfurt",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "dre",
    nomeCentro: "Dresden",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "bar",
    nomeCentro: "Barcelona",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "osl",
    nomeCentro: "Oslo",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "sie",
    nomeCentro: "Siena",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "mil",
    nomeCentro: "Milano",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "cre",
    nomeCentro: "Cremona",
    haControlli: true,
    haEsmActigrafo: true
  },
  {
    codiceCentro: "par",
    nomeCentro: "Paris",
    haControlli: true,
    haEsmActigrafo: true
  }
];
