import { CALL_API } from "../middleware/api";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const login = (email, password, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
      endpoint: "/api/login",
      method: "POST",
      body: {
        email: email,
        password: password
      },
      abortController: abortController
    }
  });
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const logout = (abortController = null) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
      endpoint: "/api/logout",
      method: "POST",
      abortController: abortController
    }
  });
};

export const GET_LOGIN_SESSIONS_REQUEST = "GET_LOGIN_SESSIONS_REQUEST";
export const GET_LOGIN_SESSIONS_SUCCESS = "GET_LOGIN_SESSIONS_SUCCESS";
export const GET_LOGIN_SESSIONS_FAILURE = "GET_LOGIN_SESSIONS_FAILURE";

export const getLoginSessions = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_LOGIN_SESSIONS_REQUEST,
        GET_LOGIN_SESSIONS_SUCCESS,
        GET_LOGIN_SESSIONS_FAILURE
      ],
      endpoint: "/api/loginSessions",
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_PAZIENTI_REQUEST = "GET_PAZIENTI_REQUEST";
export const GET_PAZIENTI_SUCCESS = "GET_PAZIENTI_SUCCESS";
export const GET_PAZIENTI_FAILURE = "GET_PAZIENTI_FAILURE";

export const getPazienti = (abortController = null) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [GET_PAZIENTI_REQUEST, GET_PAZIENTI_SUCCESS, GET_PAZIENTI_FAILURE],
      endpoint: "/api/pazienti",
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_PAZIENTE_REQUEST = "GET_PAZIENTE_REQUEST";
export const GET_PAZIENTE_SUCCESS = "GET_PAZIENTE_SUCCESS";
export const GET_PAZIENTE_FAILURE = "GET_PAZIENTE_FAILURE";

export const getPaziente = (id, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [GET_PAZIENTE_REQUEST, GET_PAZIENTE_SUCCESS, GET_PAZIENTE_FAILURE],
      endpoint: "/api/pazienti/" + id,
      method: "GET",
      abortController: abortController
    }
  });
};

export const CREA_PAZIENTE_REQUEST = "CREA_PAZIENTE_REQUEST";
export const CREA_PAZIENTE_SUCCESS = "CREA_PAZIENTE_SUCCESS";
export const CREA_PAZIENTE_FAILURE = "CREA_PAZIENTE_FAILURE";

export const creaPaziente = (paziente, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CREA_PAZIENTE_REQUEST,
        CREA_PAZIENTE_SUCCESS,
        CREA_PAZIENTE_FAILURE
      ],
      endpoint: "/api/pazienti",
      method: "POST",
      body: paziente,
      abortController: abortController
    }
  });
};

export const UPDATE_PAZIENTE_REQUEST = "UPDATE_PAZIENTE_REQUEST";
export const UPDATE_PAZIENTE_SUCCESS = "UPDATE_PAZIENTE_SUCCESS";
export const UPDATE_PAZIENTE_FAILURE = "UPDATE_PAZIENTE_FAILURE";

export const updatePaziente = (id, paziente, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_PAZIENTE_REQUEST,
        UPDATE_PAZIENTE_SUCCESS,
        UPDATE_PAZIENTE_FAILURE
      ],
      endpoint: "/api/pazienti/" + id,
      method: "PUT",
      body: paziente,
      abortController: abortController
    }
  });
};

export const UPLOAD_DATI_ACTIGRAFO_REQUEST = "UPLOAD_DATI_ACTIGRAFO_REQUEST";
export const UPLOAD_DATI_ACTIGRAFO_SUCCESS = "UPLOAD_DATI_ACTIGRAFO_SUCCESS";
export const UPLOAD_DATI_ACTIGRAFO_FAILURE = "UPLOAD_DATI_ACTIGRAFO_FAILURE";

export const uploadDatiActigrafo = (id, file, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_DATI_ACTIGRAFO_REQUEST,
        UPLOAD_DATI_ACTIGRAFO_SUCCESS,
        UPLOAD_DATI_ACTIGRAFO_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/uploadDatiActigrafo",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};

export const UPLOAD_DATI_ACTIGRAFO_2_REQUEST =
  "UPLOAD_DATI_ACTIGRAFO_2_REQUEST";
export const UPLOAD_DATI_ACTIGRAFO_2_SUCCESS =
  "UPLOAD_DATI_ACTIGRAFO_2_SUCCESS";
export const UPLOAD_DATI_ACTIGRAFO_2_FAILURE =
  "UPLOAD_DATI_ACTIGRAFO_2_FAILURE";

export const uploadDatiActigrafo2 = (id, file, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_DATI_ACTIGRAFO_2_REQUEST,
        UPLOAD_DATI_ACTIGRAFO_2_SUCCESS,
        UPLOAD_DATI_ACTIGRAFO_2_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/uploadDatiActigrafo2",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};

export const UPLOAD_DATI_ACTIGRAFO_3_REQUEST =
  "UPLOAD_DATI_ACTIGRAFO_3_REQUEST";
export const UPLOAD_DATI_ACTIGRAFO_3_SUCCESS =
  "UPLOAD_DATI_ACTIGRAFO_3_SUCCESS";
export const UPLOAD_DATI_ACTIGRAFO_3_FAILURE =
  "UPLOAD_DATI_ACTIGRAFO_3_FAILURE";

export const uploadDatiActigrafo3 = (id, file, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_DATI_ACTIGRAFO_3_REQUEST,
        UPLOAD_DATI_ACTIGRAFO_3_SUCCESS,
        UPLOAD_DATI_ACTIGRAFO_3_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/uploadDatiActigrafo3",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};

export const UPLOAD_DATI_ACTIGRAFO_4_REQUEST =
  "UPLOAD_DATI_ACTIGRAFO_4_REQUEST";
export const UPLOAD_DATI_ACTIGRAFO_4_SUCCESS =
  "UPLOAD_DATI_ACTIGRAFO_4_SUCCESS";
export const UPLOAD_DATI_ACTIGRAFO_4_FAILURE =
  "UPLOAD_DATI_ACTIGRAFO_4_FAILURE";

export const uploadDatiActigrafo4 = (id, file, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_DATI_ACTIGRAFO_4_REQUEST,
        UPLOAD_DATI_ACTIGRAFO_4_SUCCESS,
        UPLOAD_DATI_ACTIGRAFO_4_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/uploadDatiActigrafo4",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};

export const UPLOAD_DATI_ACTIGRAFO_5_REQUEST =
  "UPLOAD_DATI_ACTIGRAFO_5_REQUEST";
export const UPLOAD_DATI_ACTIGRAFO_5_SUCCESS =
  "UPLOAD_DATI_ACTIGRAFO_5_SUCCESS";
export const UPLOAD_DATI_ACTIGRAFO_5_FAILURE =
  "UPLOAD_DATI_ACTIGRAFO_5_FAILURE";

export const uploadDatiActigrafo5 = (id, file, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_DATI_ACTIGRAFO_5_REQUEST,
        UPLOAD_DATI_ACTIGRAFO_5_SUCCESS,
        UPLOAD_DATI_ACTIGRAFO_5_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/uploadDatiActigrafo5",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};

export const GET_CONTROLLI_REQUEST = "GET_CONTROLLI_REQUEST";
export const GET_CONTROLLI_SUCCESS = "GET_CONTROLLI_SUCCESS";
export const GET_CONTROLLI_FAILURE = "GET_CONTROLLI_FAILURE";

export const getControlli = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_CONTROLLI_REQUEST,
        GET_CONTROLLI_SUCCESS,
        GET_CONTROLLI_FAILURE
      ],
      endpoint: "/api/controlli",
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_OPERATORE_REQUEST = "GET_OPERATORE_REQUEST";
export const GET_OPERATORE_SUCCESS = "GET_OPERATORE_SUCCESS";
export const GET_OPERATORE_FAILURE = "GET_OPERATORE_FAILURE";

export const getOperatore = (id, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_OPERATORE_REQUEST,
        GET_OPERATORE_SUCCESS,
        GET_OPERATORE_FAILURE
      ],
      endpoint: "/api/operatori/" + id,
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_OPERATORI_REQUEST = "GET_OPERATORI_REQUEST";
export const GET_OPERATORI_SUCCESS = "GET_OPERATORI_SUCCESS";
export const GET_OPERATORI_FAILURE = "GET_OPERATORI_FAILURE";

export const getOperatori = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_OPERATORI_REQUEST,
        GET_OPERATORI_SUCCESS,
        GET_OPERATORI_FAILURE
      ],
      endpoint: "/api/operatori",
      method: "GET",
      abortController: abortController
    }
  });
};

export const CREA_OPERATORE_REQUEST = "CREA_OPERATORE_REQUEST";
export const CREA_OPERATORE_SUCCESS = "CREA_OPERATORE_SUCCESS";
export const CREA_OPERATORE_FAILURE = "CREA_OPERATORE_FAILURE";

export const creaOperatore = (operatore, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CREA_OPERATORE_REQUEST,
        CREA_OPERATORE_SUCCESS,
        CREA_OPERATORE_FAILURE
      ],
      endpoint: "/api/operatori",
      method: "POST",
      body: operatore,
      abortController: abortController
    }
  });
};

export const UPDATE_OPERATORE_REQUEST = "UPDATE_OPERATORE_REQUEST";
export const UPDATE_OPERATORE_SUCCESS = "UPDATE_OPERATORE_SUCCESS";
export const UPDATE_OPERATORE_FAILURE = "UPDATE_OPERATORE_FAILURE";

export const updateOperatore = (id, operatore, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_OPERATORE_REQUEST,
        UPDATE_OPERATORE_SUCCESS,
        UPDATE_OPERATORE_FAILURE
      ],
      endpoint: "/api/operatori/" + id,
      method: "PUT",
      body: operatore,
      abortController: abortController
    }
  });
};

export const UPDATE_PASSWORD_OPERATORE_REQUEST =
  "UPDATE_PASSWORD_OPERATORE_REQUEST";
export const UPDATE_PASSWORD_OPERATORE_SUCCESS =
  "UPDATE_PASSWORD_OPERATORE_SUCCESS";
export const UPDATE_PASSWORD_OPERATORE_FAILURE =
  "UPDATE_PASSWORD_OPERATORE_FAILURE";

export const updatePasswordOperatore = (id, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_PASSWORD_OPERATORE_REQUEST,
        UPDATE_PASSWORD_OPERATORE_SUCCESS,
        UPDATE_PASSWORD_OPERATORE_FAILURE
      ],
      endpoint: "/api/operatori/" + id + "/password",
      method: "PUT",
      abortController: abortController
    }
  });
};

export const GET_RISPOSTE_QUESTIONARIO_REQUEST =
  "GET_RISPOSTE_QUESTIONARIO_REQUEST";
export const GET_RISPOSTE_QUESTIONARIO_SUCCESS =
  "GET_RISPOSTE_QUESTIONARIO_SUCCESS";
export const GET_RISPOSTE_QUESTIONARIO_FAILURE =
  "GET_RISPOSTE_QUESTIONARIO_FAILURE";

export const getRisposteQuestionario = (
  userId,
  questionarioKey,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_RISPOSTE_QUESTIONARIO_REQUEST,
        GET_RISPOSTE_QUESTIONARIO_SUCCESS,
        GET_RISPOSTE_QUESTIONARIO_FAILURE
      ],
      endpoint: "/api/pazienti/" + userId + "/questionario/" + questionarioKey,
      method: "GET",
      abortController: abortController
    }
  });
};

export const UPDATE_RISPOSTE_QUESTIONARIO_REQUEST =
  "UPDATE_RISPOSTE_QUESTIONARIO_REQUEST";
export const UPDATE_RISPOSTE_QUESTIONARIO_SUCCESS =
  "UPDATE_RISPOSTE_QUESTIONARIO_SUCCESS";
export const UPDATE_RISPOSTE_QUESTIONARIO_FAILURE =
  "UPDATE_RISPOSTE_QUESTIONARIO_FAILURE";

export const updateRisposteQuestionario = (
  userId,
  questionarioKey,
  data,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_RISPOSTE_QUESTIONARIO_REQUEST,
        UPDATE_RISPOSTE_QUESTIONARIO_SUCCESS,
        UPDATE_RISPOSTE_QUESTIONARIO_FAILURE
      ],
      endpoint: "/api/pazienti/" + userId + "/questionario/" + questionarioKey,
      method: "PUT",
      body: data,
      abortController: abortController
    }
  });
};

export const GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_REQUEST =
  "GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_REQUEST";
export const GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_SUCCESS =
  "GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_SUCCESS";
export const GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_FAILURE =
  "GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_FAILURE";

export const getCompletamentoQuestionariPerUtente = (
  userId,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_REQUEST,
        GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_SUCCESS,
        GET_COMPLETAMENTO_QUESTIONARI_PER_UTENTE_FAILURE
      ],
      endpoint: "/api/pazienti/" + userId + "/questionariCompletamento",
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_REQUEST =
  "GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_REQUEST";
export const GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_SUCCESS =
  "GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_SUCCESS";
export const GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_FAILURE =
  "GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_FAILURE";

export const getSmartphoneConnectionForPaziente = (
  id,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_REQUEST,
        GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_SUCCESS,
        GET_SMARTPHONE_CONNECTION_FOR_PAZIENTE_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/smartphoneConnection",
      method: "GET",
      abortController: abortController
    }
  });
};

export const UPDATE_SMARTPHONE_CONNECTION_REQUEST =
  "UPDATE_SMARTPHONE_CONNECTION_REQUEST";
export const UPDATE_SMARTPHONE_CONNECTION_SUCCESS =
  "UPDATE_SMARTPHONE_CONNECTION_SUCCESS";
export const UPDATE_SMARTPHONE_CONNECTION_FAILURE =
  "UPDATE_SMARTPHONE_CONNECTION_FAILURE";

export const updateSmartphoneConnection = (
  id,
  smartphoneConnection,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_SMARTPHONE_CONNECTION_REQUEST,
        UPDATE_SMARTPHONE_CONNECTION_SUCCESS,
        UPDATE_SMARTPHONE_CONNECTION_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/smartphoneConnection",
      method: "PUT",
      body: smartphoneConnection,
      abortController: abortController
    }
  });
};

export const SEND_TEST_NOTIFICATION_REQUEST = "SEND_TEST_NOTIFICATION_REQUEST";
export const SEND_TEST_NOTIFICATION_SUCCESS = "SEND_TEST_NOTIFICATION_SUCCESS";
export const SEND_TEST_NOTIFICATION_FAILURE = "SEND_TEST_NOTIFICATION_FAILURE";

export const sendTestNotification = (id, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SEND_TEST_NOTIFICATION_REQUEST,
        SEND_TEST_NOTIFICATION_SUCCESS,
        SEND_TEST_NOTIFICATION_FAILURE
      ],
      endpoint: "/api/pazienti/" + id + "/notification/test",
      method: "POST",
      abortController: abortController
    }
  });
};

export const GET_STATS_REQUEST = "GET_STATS_REQUEST";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAILURE = "GET_STATS_FAILURE";

export const getStats = (abortController = null) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [GET_STATS_REQUEST, GET_STATS_SUCCESS, GET_STATS_FAILURE],
      endpoint: "/api/stats",
      method: "GET",
      abortController: abortController
    }
  });
};

export const GET_STATS_TOTALI_REQUEST = "GET_STATS_TOTALI_REQUEST";
export const GET_STATS_TOTALI_SUCCESS = "GET_STATS_TOTALI_SUCCESS";
export const GET_STATS_TOTALI_FAILURE = "GET_STATS_TOTALI_FAILURE";

export const getStatsTotali = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_STATS_TOTALI_REQUEST,
        GET_STATS_TOTALI_SUCCESS,
        GET_STATS_TOTALI_FAILURE
      ],
      endpoint: "/api/statsTotali",
      method: "GET",
      abortController: abortController
    }
  });
};

export const MOBILE_LOGIN_REQUEST = "MOBILE_LOGIN_REQUEST";
export const MOBILE_LOGIN_SUCCESS = "MOBILE_LOGIN_SUCCESS";
export const MOBILE_LOGIN_FAILURE = "MOBILE_LOGIN_FAILURE";

export const mobileLogin = (
  codiceUtente,
  connectionPin,
  notificationToken,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [MOBILE_LOGIN_REQUEST, MOBILE_LOGIN_SUCCESS, MOBILE_LOGIN_FAILURE],
      endpoint: "/api/mobile/login",
      method: "POST",
      body: {
        codiceUtente: codiceUtente,
        connectionPin: connectionPin,
        notificationToken: notificationToken
      },
      abortController: abortController
    }
  });
};

export const MOBILE_GET_USER_REQUEST = "MOBILE_GET_USER_REQUEST";
export const MOBILE_GET_USER_SUCCESS = "MOBILE_GET_USER_SUCCESS";
export const MOBILE_GET_USER_FAILURE = "MOBILE_GET_USER_FAILURE";

export const mobileGetUser = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        MOBILE_GET_USER_REQUEST,
        MOBILE_GET_USER_SUCCESS,
        MOBILE_GET_USER_FAILURE
      ],
      endpoint: "/api/mobile/getUser",
      method: "GET",
      abortController: abortController
    }
  });
};

export const INVIA_NOTIFICA_CON_RISPOSTE_REQUEST =
  "INVIA_NOTIFICA_CON_RISPOSTE_REQUEST";
export const INVIA_NOTIFICA_CON_RISPOSTE_SUCCESS =
  "INVIA_NOTIFICA_CON_RISPOSTE_SUCCESS";
export const INVIA_NOTIFICA_CON_RISPOSTE_FAILURE =
  "INVIA_NOTIFICA_CON_RISPOSTE_FAILURE";

export const inviaNotificaConRisposte = (notifica, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INVIA_NOTIFICA_CON_RISPOSTE_REQUEST,
        INVIA_NOTIFICA_CON_RISPOSTE_SUCCESS,
        INVIA_NOTIFICA_CON_RISPOSTE_FAILURE
      ],
      endpoint: "/api/mobile/inviaRisposteNotifica",
      method: "POST",
      body: notifica,
      abortController: abortController
    }
  });
};

export const EXPORT_DATA_REQUEST = "EXPORT_DATA_REQUEST";
export const EXPORT_DATA_SUCCESS = "EXPORT_DATA_SUCCESS";
export const EXPORT_DATA_FAILURE = "EXPORT_DATA_FAILURE";

export const exportData = (abortController = null) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [EXPORT_DATA_REQUEST, EXPORT_DATA_SUCCESS, EXPORT_DATA_FAILURE],
      endpoint: "/api/export/data",
      method: "GET",
      abortController: abortController
    }
  });
};

export const EXPORT_DATA_ESM_REQUEST = "EXPORT_DATA_ESM_REQUEST";
export const EXPORT_DATA_ESM_SUCCESS = "EXPORT_DATA_ESM_SUCCESS";
export const EXPORT_DATA_ESM_FAILURE = "EXPORT_DATA_ESM_FAILURE";

export const exportDataESM = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        EXPORT_DATA_ESM_REQUEST,
        EXPORT_DATA_ESM_SUCCESS,
        EXPORT_DATA_ESM_FAILURE
      ],
      endpoint: "/api/export/dataESM",
      method: "GET",
      abortController: abortController
    }
  });
};

export const EXPORT_DATA_DIARI_REQUEST = "EXPORT_DATA_DIARI_REQUEST";
export const EXPORT_DATA_DIARI_SUCCESS = "EXPORT_DATA_DIARI_SUCCESS";
export const EXPORT_DATA_DIARI_FAILURE = "EXPORT_DATA_DIARI_FAILURE";

export const exportDataDiari = (abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [
        EXPORT_DATA_DIARI_REQUEST,
        EXPORT_DATA_DIARI_SUCCESS,
        EXPORT_DATA_DIARI_FAILURE
      ],
      endpoint: "/api/export/dataDiari",
      method: "GET",
      abortController: abortController
    }
  });
};

export const EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_REQUEST =
  "EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_REQUEST";
export const EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_SUCCESS =
  "EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_SUCCESS";
export const EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_FAILURE =
  "EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_FAILURE";

export const exportDataListaUtentiConDatiActigrafo = (
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_REQUEST,
        EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_SUCCESS,
        EXPORT_DATA_LISTA_UTENTI_CON_DATI_ACTIGRAFO_FAILURE
      ],
      endpoint: "/api/export/listaUtentiConDatiActigrafo",
      method: "GET",
      abortController: abortController
    }
  });
};

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const deleteUser = (id, abortController = null) => (
  dispatch,
  getState
) => {
  return dispatch({
    [CALL_API]: {
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
      endpoint: "/api/delete-user/" + id,
      method: "POST",
      abortController: abortController
    }
  });
};

export const UPLOAD_EXCEL_RISPOSTE_NOT_USED_REQUEST =
  "UPLOAD_EXCEL_RISPOSTE_NOT_USED_REQUEST";
export const UPLOAD_EXCEL_RISPOSTE_NOT_USED_SUCCESS =
  "UPLOAD_EXCEL_RISPOSTE_NOT_USED_SUCCESS";
export const UPLOAD_EXCEL_RISPOSTE_NOT_USED_FAILURE =
  "UPLOAD_EXCEL_RISPOSTE_NOT_USED_FAILURE";

export const ___uploadExcelRisposte_not_used___ = (
  file,
  abortController = null
) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPLOAD_EXCEL_RISPOSTE_NOT_USED_REQUEST,
        UPLOAD_EXCEL_RISPOSTE_NOT_USED_SUCCESS,
        UPLOAD_EXCEL_RISPOSTE_NOT_USED_FAILURE
      ],
      endpoint: "/api/uploadExcelRisposte",
      method: "POST",
      body: file,
      abortController: abortController
    }
  });
};
