import React from "react";
import { Component } from "react";
import AsyncData from "app/elements/AsyncData";
import Loading from "app/elements/Loading";
import {
  Alert,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody
} from "reactstrap";
import {
  getPaziente,
  updatePaziente,
  getOperatore,
  updateOperatore
} from "app/actions";
import { get } from "lodash";
import PreventClose from "app/elements/PreventClose.js";
import DeleteUser from "app/elements/DeleteUser.js";
import Obiettivo2 from "app/components/Obiettivo2.js";
import LegendaColoriQuestionari from "app/elements/LegendaColoriQuestionari.js";
import ListaQuestionariUtente from "app/components/ListaQuestionariUtente.js";
import {
  getQuestionariPerPaziente,
  questionariPerOperatore
} from "common/utils/questionari.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import OperatoreForm from "app/components/OperatoreForm.js";
import { NUMERO_DI_VOLTE_ESM } from "common/datiPortale";

class AbstractDettaglioPartecipante extends Component {
  // PROPS:
  // FormComponent (if isOperatore=false), isOperatore
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, FormComponent, isOperatore } = this.props;

    let loaderAction, saveAction;

    if (isOperatore) {
      loaderAction = this.props.getOperatore;
      saveAction = this.props.updateOperatore;
    } else {
      loaderAction = this.props.getPaziente;
      saveAction = this.props.updatePaziente;
    }

    return (
      <AsyncData
        loaderAction={loaderAction}
        loaderArgs={[get(this.props, "userId")]}
        saveAction={saveAction}
      >
        {({
          loading,
          loadingError,
          data,
          setData,
          hasChanges,
          saveData,
          saving,
          savingError,
          cancelChanges
        }) => {
          console.log({
            loading,
            loadingError,
            data,
            hasChanges,
            saving,
            savingError
          });
          if (loading) {
            return <Loading />;
          }
          if (loadingError) {
            return <Alert color="danger">{loadingError.message}</Alert>;
          }

          const listaQuestionari = isOperatore
            ? questionariPerOperatore
            : getQuestionariPerPaziente(data);

          return (
            <>
              <Row>
                <Col sm={12}>
                  <Card>
                    <CardHeader>
                      {isOperatore
                        ? t("dettaglio_operatore_label")
                        : t("dettaglio_partecipante_label")}{" "}
                      <div className="float-right">
                        <div style={{ display: "inline-block" }}>
                          {savingError && savingError.message ? (
                            <div className="text-danger">
                              {savingError.message}
                            </div>
                          ) : null}
                        </div>
                        <Button
                          color="success"
                          size="sm"
                          outline={false}
                          onClick={() => {
                            saveData(get(data, "id"), data);
                          }}
                        >
                          {t("salva_label")}
                        </Button>{" "}
                        <PreventClose
                          shouldPrevent={hasChanges}
                          message={t("prevent_close_warning_message_generic")}
                        />
                        <Button
                          color="danger"
                          size="sm"
                          outline={false}
                          disabled={!hasChanges}
                          onClick={() => {
                            cancelChanges();
                          }}
                        >
                          {t("annulla_cambiamenti_label")}
                        </Button>
                        <DeleteUser user={data} />
                      </div>
                    </CardHeader>

                    <CardBody>
                      <Row>
                        <Col>
                          {isOperatore ? (
                            <OperatoreForm
                              operatore={data}
                              updateOperatore={setData}
                              operatoreErrors={savingError}
                              isCreate={false}
                            />
                          ) : (
                            <FormComponent
                              paziente={data}
                              updatePaziente={setData}
                              pazienteErrors={savingError}
                              isCreate={false}
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {!isOperatore && get(data, "partecipaObiettivo2")
                ? new Array(NUMERO_DI_VOLTE_ESM).fill(0).map((_, index) => {
                    return (
                      <Row key={index}>
                        <Col sm={12}>
                          <Card>
                            <CardHeader>
                              {t("esm_actigrafo_label")}{" "}
                              {index ? index + 1 : ""}
                            </CardHeader>

                            <CardBody>
                              <Row>
                                <Col>
                                  <Obiettivo2
                                    paziente={data}
                                    updatePaziente={setData}
                                    fileActigrafoGrezziPathIndex={index}
                                  />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })
                : null}

              <Row>
                <Col sm={12}>
                  <Card>
                    <CardHeader>{t("questionari_label")}</CardHeader>

                    <CardBody>
                      <Row>
                        <Col>
                          <LegendaColoriQuestionari />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <ListaQuestionariUtente
                            user={data}
                            listaQuestionari={listaQuestionari}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          );
        }}
      </AsyncData>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: get(ownProps, "match.params.id")
  };
};
const mapDispatchToProps = {
  getPaziente: getPaziente,
  updatePaziente: updatePaziente,
  getOperatore: getOperatore,
  updateOperatore: updateOperatore
};

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AbstractDettaglioPartecipante)
  )
);
